//BRAND
export const name = 'AiSyllabi';
export const company = 'UMU INC';
export const websiteURL = 'https://aisyllabi.web.app';
export const serverURL = 'https://aisyllabi.onrender.com';
export const logo = 'https://firebasestorage.googleapis.com/v0/b/aisyllabi.appspot.com/o/cry84ce_a_simple_logo_blackxand_white_colors_for_a_course_study_d00548f7-2877-458e-849d-df336f60a042.png?alt=media&token=bee567a9-4530-4d27-977f-ded7cfaf935b';
export const razorpayEnabled = false;
export const paypalEnabled = true;

//PRICING :-

//FREE 
export const FreeType = 'Free Plan';
export const FreeCost = 0;
export const FreeTime = '';

//MONTHLY 
export const MonthType = 'Monthly Plan';
export const MonthCost = 8;
export const MonthTime = 'month';

//YEARLY 
export const YearType = 'Yearly Plan';
export const YearCost = 88;
export const YearTime = 'year';

//TESTIMONIAL
export const review = "The AI Course Generator revolutionized my content creation process, providing accurate and relevant topics effortlessly. It's a time-saving powerhouse that enhances the quality and relevance of my courses. A must-have tool for educators seeking efficiency and impactful online learning experiences.";
export const from = "McKinley Garland";
export const profession = 'AiSyllabi';
export const photoURL = 'https://firebasestorage.googleapis.com/v0/b/aisyllabi.appspot.com/o/cry84ce_a_simple_logo_blackxand_white_colors_for_a_course_study_d00548f7-2877-458e-849d-df336f60a042.png?alt=media&token=bee567a9-4530-4d27-977f-ded7cfaf935b';

//PAYPAL
export const paypalPlanIdOne = "P-8WF62972UU506901JMYLNI3Q";
export const paypalPlanIdTwo = "P-35R48673WT706425UMYLNJSQ";

//RAZORPAY
export const razorpayKeyId = "rzp_test_uqALjZHyTyuiOm";
export const razorpayPlanIdOne = "plan_NMvvtDfznbRp6V";
export const razorpayPlanIdTwo = "plan_NMRc9HARBQRLWA";
